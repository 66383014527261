<template>
  <b-card>
    <FormBuilder
      label="Interest"
      create
      :fields="fields"
      :loading-submit="$store.state.interest.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'description',
          label: 'Description',
          type: 'textarea',
        },
        {
          key: 'sort',
          label: 'Sort',
          type: 'number',
          notes: 'Is the position number where you put this interest in on-boarding form',
        },
      ],
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('interest/create', data)
        .then(callback)
    },
  },
}
</script>

  <style scoped>

  </style>
